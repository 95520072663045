import React, { ReactElement } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { PlayerProps } from "./player"
import { formatDuration, formatEp, guessEp, parseDuration, selectGuestSocial, selectTitle } from "../utilities"
import slugify from "slugify"

const EpisodeListItem = ({ edge, digits, setEpisode }: {
  edge: GatsbyTypes.PodcastQueryQuery["allFeedPodcast"]["edges"][0],
  digits: number,
  setEpisode: React.Dispatch<React.SetStateAction<PlayerProps | undefined>>
}): ReactElement | null => {
  if(!edge?.node) return null

  const longDate = edge.node.longDate,
    shortDate = edge.node.shortDate,
    url = `/${slugify(edge.node.title ?? ``, { lower: true, remove: /[*+~.,()'"‘’”“!?:@#]/g })}`,
    { state } = useLocation(),
    linkState = Object.assign((state as any)?.sort ? { sort: (state as any).sort } : { sort: false }, { autoplay: true }),
    ep = formatEp(guessEp(edge.node), digits),
    title = selectTitle(edge?.node?.title ?? ``),
    // 04/06/23 .... uh... no!
    // 03/28/23 Feed duration now differs from episode duration by consistent 45s
    // duration = formatDuration(parseInt(edge?.node?.itunes?.duration ?? `45`, 10) - 45),
    duration = formatDuration(parseInt(edge?.node?.itunes?.duration ?? `0`, 10)),
    content = edge.node.content.encoded?.split(`</p>`)[0].split(`<p>`)[1]
      ?? edge.node.content.encoded?.split(`
`)[0],
    guestSocial = selectGuestSocial(edge.node.content.encoded ?? ``),
    episodeDescriptionHTML = content + guestSocial,
    handleClick = () => {
      if(!(edge?.node && edge.node?.enclosure && edge.node.enclosure.url && edge.node.enclosure.type && edge.node.itunes?.duration)) return

      setEpisode({
        autoplay: true,
        duration: parseDuration(duration),
        next: undefined, // Next / previous objects could be resolved during iteration via layout.
        previous: undefined, // It’s not necessary and state needs to be updated on Episode mount regardless.
        src: edge.node.enclosure.url,
        title: `Ep. ${ep} ${title}`,
        type: edge.node.enclosure.type,
      })
    }

  return (
    <>
      <div className="hidden lg:block mb-10">
        <p className="text-2xs mb-2">{longDate}</p>
        <h2 className="block text-xl mb-2">
          <Link className="relative group no-underline py-0.5" to={url} onClick={handleClick} state={linkState}>
            <span className="absolute -translate-x-full pr-2.5 text-xl opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-100">
              <span className="inline-block text-xs align-middle -mt-px text-black">
                ▶
              </span>
            </span>
            Ep. {ep} <span className="font-bold">{title}</span> {duration}
          </Link>
        </h2>
        <div className="text-xs children-font-normal" dangerouslySetInnerHTML={{ __html: episodeDescriptionHTML }} />
      </div>
      <div className="lg:hidden">
        <Link className="group py-0.5 line-clamp-1 text-sm no-underline" to={url} onClick={handleClick} state={linkState}>
          <span className="inline-flex w-full items-center">
            <span className="text-2xs text-black">
              {ep}
            </span>
            <span className="mx-2 font-bold line-clamp-1">{title}</span>
            <span className="ml-auto text-2xs text-black">{duration}</span>
            <span className="ml-2 text-2xs text-black">{shortDate}</span>
          </span>
        </Link>
      </div>
    </>
  )
}

export default EpisodeListItem
export { EpisodeListItem }