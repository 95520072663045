const windowIfAvailable = (): Window | HTMLElement | any => {
  if(typeof window === `undefined`) return {}
  else return window
}

const isNode = (reference: unknown): reference is Node => {
  return reference instanceof Node
}

const isHTMLElement = (reference: unknown): reference is HTMLElement => {
  return reference instanceof HTMLElement
}

const containsActiveElement = (reference: unknown) => {
  if(!isNode(reference)) return false
  if(typeof document === `undefined`) return false
  if(reference.contains(document.activeElement)) return true
  return false
}

const isActiveElement = (reference: unknown) => {
  if(!isHTMLElement(reference)) return false
  if(typeof document === `undefined`) return false
  return reference === document.activeElement
}

const isOrContainsActiveElement = (reference: unknown) => {
  return isActiveElement(reference) || containsActiveElement(reference)
}

const countDigits = (number: number): number => {
  let digits = 0
  while (number !== 0) {
    number = Math.floor(number / 10)
    digits++
  }
  return digits
}

const padStart = (increment: number) => `${increment}`.padStart(2, `0`)

const formatDuration = (duration: number): string => {
  const seconds = Math.floor(duration % 60),
    minutes = Math.floor(duration / 60 % 60),
    hours = Math.floor(duration / 60 / 60)

  return `${padStart(hours)}:${padStart(minutes)}:${padStart(seconds)}`
}

const parseDuration = (duration: string): number => {
  return duration.split(`:`)?.map((v, i) => parseInt(v, 10) * Math.pow(60, 2 - i)).reduce((p, c) => p + c) ?? 0
}

const redactNoEp = (episode: string) => episode === `000` ? `XXX` : episode

const formatEp = (episode: number, digits: number): string => redactNoEp(`${episode}`.padStart(digits, `0`))

const guessEp = (podcast: GatsbyTypes.PodcastQueryQuery["allFeedPodcast"]["edges"][0]["node"] | GatsbyTypes.PodcastByGuidQuery["feedPodcast"]): number =>  {
  const epFromTitle = podcast?.title && parseInt(podcast?.title.split(`.`)[0], 10),
    epFromItunes = podcast?.itunes?.episode && parseInt(podcast.itunes.episode, 10)
      
  if(epFromTitle && !isNaN(epFromTitle)) return epFromTitle
  if(epFromItunes) return epFromItunes
  return 0
}

const selectTitle = (title: string): string => {
  return title.indexOf(`- `) > -1
    ? title.split(`- `)[1]
    : title.indexOf(`-`) > -1
      ? title.split(`-`)[1]
      : title.indexOf(`. `) > -1
        ? title.split(`. `)[1]
        : title
}

const selectGuestSocial = (description: string): string => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:twitter|instagram)\.com\/[^&?"><\/\s]+/gi,
    matches = description.match(regex)?.filter(match => match.indexOf(`donetodeath`) === -1 && match.indexOf(`themjeans`) === -1),
    alreadyMatched: string[] = [],
    html = matches?.reduce((value, match) => {
      const content = match.replace(/https?:\/\//, ``).replace(/www\./, ``).replace(/"/g, ``).toLowerCase()

      // Don’t match the same handle twice
      if(alreadyMatched.indexOf(content) > -1) return value
      alreadyMatched.push(content)

      return value + ` <a href="//${content}">${content}</a>.`
    }, ``) ?? ``

  return html
}

export {
  containsActiveElement,
  countDigits,
  formatDuration,
  formatEp,
  guessEp,
  isActiveElement,
  isHTMLElement,
  isNode,
  isOrContainsActiveElement,
  parseDuration,
  selectGuestSocial,
  selectTitle,
  windowIfAvailable,
}