/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as React from "react"
import { Layout } from "./src/components/layout"
import "./src/css/index.css"
 
export const wrapPageElement = ({ element, props }) => {  
  return (
    <Layout {...props}>{element}</Layout>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return location?.state?.scroll ?? false
}