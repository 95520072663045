import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useState, useRef, ReactElement, ChangeEvent, useEffect } from "react"
import { useEventListener } from "../hooks/useEventListener"
import { formatDuration } from "../utilities"

const query = graphql`
  query PodcastCoverQuery {
    podcastCover: file(relativePath: {eq: "how-long-gone-cover.jpg"}) {
      childImageSharp {
				x128: resize(width: 128) {
        	src
        }
        x256: resize(width: 256) {
					src
        }
        x512: resize(width: 512) {
          src
        }
        x1024: resize(width: 1024) {
					src
        }
      }
    }
  }
`

export interface AdjacentPodcast {
  duration: number,
  src: string,
  title: string,
  type: string,
  uid: string,
}

export interface PlayerProps {
  autoplay?: boolean
  duration?: number
  next?: AdjacentPodcast
  previous?: AdjacentPodcast
  src?: string
  title?: string
  type?: string
}

const Player = ({ autoplay, duration = 0, next, previous, src, title, type, setEpisode }: PlayerProps & { setEpisode: React.Dispatch<React.SetStateAction<PlayerProps | undefined>> }): ReactElement => {
  const audioElement = useRef<HTMLAudioElement>(null)

	const seekRange = useRef<HTMLInputElement>(null),
    insufficientData = !(duration && src && title && type),
    [loading, setLoading] = useState(false),
    [playing, setPlaying] = useState(false),
    [totalTime, setTotalTime] = useState(duration),
    [currentTime, setCurrentTime] = useState(0),
    [formattedTime, setFormattedTime] = useState(formatDuration(currentTime)),
    [formattedTimeRemaining, setFormattedTimeRemaining] = useState(formatDuration(Math.ceil(totalTime - currentTime)))

  const seek = (e: ChangeEvent) => {
      if(!audioElement.current) return
      audioElement.current.currentTime = parseInt((e.target as HTMLInputElement).value, 10)
      const newCurrentTime = parseInt((e.target as HTMLInputElement).value, 10)
      setCurrentTime(newCurrentTime)
      setFormattedTime(formatDuration(newCurrentTime))
      setFormattedTimeRemaining(formatDuration(Math.ceil(totalTime - newCurrentTime)))
    },
    play = () => {
      if(!audioElement.current) return
      if(audioElement.current.paused) audioElement.current.play().catch((error) => console.log(error))
    },
    pause = () => {
      if(!audioElement.current) return
      if(!audioElement.current.paused) audioElement.current.pause()
    },
    skip = (forward = true, increment = 15) => {
      if(!audioElement.current) return
      const newTime = audioElement.current.currentTime + (forward ? 1 : -1) * increment,
        newConstrainedTime = Math.min(totalTime, Math.max(0, newTime))
      audioElement.current.currentTime = newConstrainedTime
    }

  const changeEpisode = (episode?: AdjacentPodcast) => {
    if(!episode) return
    const { uid, ...rest } = episode
    // Pause current media
    pause()
    // If track details are present
    if(episode.duration && episode.src && episode.type) setEpisode({
      autoplay: true,
      ...rest
    })
    // If uid is present
    if(uid) navigate(`/${uid}`, {
      state: {
        autoplay: true
      }
    })
  }

  const [artwork, setArtwork] = useState<MediaImage[]>([]),
    data = useStaticQuery(query)

  useEffect(() => {
    setArtwork([{
        src: `${window.location.protocol}//${window.location.host}${data.podcastCover?.childImageSharp?.x128?.src}`,
        sizes: `128x128`,
        type: `type/jpeg`,
      }, {
        src: `${window.location.protocol}//${window.location.host}${data.podcastCover?.childImageSharp?.x256?.src}`,
        sizes: `256x256`,
        type: `type/jpeg`,
      }, {
        src: `${window.location.protocol}//${window.location.host}${data.podcastCover?.childImageSharp?.x512?.src}`,
        sizes: `512x512`,
        type: `type/jpeg`,
      }, {
        src: `${window.location.protocol}//${window.location.host}${data.podcastCover?.childImageSharp?.x1024?.src}`,
        sizes: `1024x1024`,
        type: `type/jpeg`,
      },
    ])
  }, [])

  useEventListener(
    `waiting`,
    () => setLoading(true),
    audioElement.current
  )

  useEventListener(
    `canplay`,
    () => {
      setLoading(false)
      // Update duration since length from RSS does not include ads
      // 04/06/23: ..... durations weird again ... turning back on
      // 03/28/23: This is no longer necessary because RSS feed format
      // change now reflects correct episode durations with advertisements
      if(audioElement?.current?.duration) setTotalTime(Math.floor(audioElement.current.duration))
    },
    audioElement.current
  )

  // Update range and timestamps throughout playback
  useEventListener(
    `timeupdate`,
    () => {
      if(!seekRange.current || !audioElement.current) return
      seekRange.current.value = `${audioElement.current.currentTime}`
      const newCurrentTime = Math.floor(audioElement.current.currentTime)
      setCurrentTime(newCurrentTime)
      setFormattedTime(formatDuration(newCurrentTime))
      setFormattedTimeRemaining(formatDuration(Math.ceil(totalTime - newCurrentTime)))
    },
    audioElement.current
  )

  // Update Media Session when user seeks
  useEventListener(
    `seeked`,
    () => {
      if(!audioElement.current) return
      const newCurrentTime = audioElement.current.currentTime

      if(`mediaSession` in navigator && navigator.mediaSession) {
        if (`setPositionState` in navigator.mediaSession && navigator.mediaSession.setPositionState) {
          try {
            navigator.mediaSession.setPositionState({
              duration: totalTime,
              playbackRate: 1,
              position: newCurrentTime,
            })
          } catch(error) {
            // console.log(error)
          }
        }
      }
    },
    audioElement.current
  )

  useEventListener(
    `ended`,
    () => setPlaying(false),
    audioElement.current
  )

  useEventListener(
    `pause`,
    () => setPlaying(false),
    audioElement.current
  )

  useEventListener(
    `play`,
    () => setPlaying(true),
    audioElement.current
  )

  // Update position state when duration changes
  useEffect(() => {
    setFormattedTimeRemaining(formatDuration(Math.ceil(totalTime - currentTime)))

    if(`mediaSession` in navigator && navigator.mediaSession) {
      // Reset current time
      if (`setPositionState` in navigator.mediaSession && navigator.mediaSession.setPositionState) {
        navigator.mediaSession.setPositionState({
          duration: totalTime,
          playbackRate: 1,
          position: 0,
        })
      }
    }
  }, [totalTime])

  // Update player actions when next/previous change
  useEffect(() => {
    if(`mediaSession` in navigator && navigator.mediaSession) {
      // Update player when actions occur via Media Session API
      try {
        navigator.mediaSession.setActionHandler(`previoustrack`, () => changeEpisode(previous))
        navigator.mediaSession.setActionHandler(`nexttrack`, () => changeEpisode(next))
        navigator.mediaSession.setActionHandler(`seekforward`, () => skip(true))
        navigator.mediaSession.setActionHandler(`seekbackward`, () => skip(false))
        navigator.mediaSession.setActionHandler(`seekto`, ({ action, fastSeek, seekTime }) => {
          if(!audioElement.current) return
          audioElement.current.currentTime = Math.min(totalTime, seekTime)
        })
      } catch(error) {
        console.log(error)
      }
    }
  }, [next, previous])

  useEffect(() => {
    setTotalTime(duration)
  }, [duration])

  // Load new media when src changes
  useEffect(() => {
    pause()
    audioElement.current?.load()
    if(autoplay) {
      play()
    } else {
      // Update play state because pause event may not fire
      setPlaying(false)
    }
  }, [src])

  // Update artwork
  useEffect(() => {
    if(artwork.length) {
      if(`mediaSession` in navigator && navigator.mediaSession) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title,
          artist: `How Long Gone`,
          artwork,
        })
      }
    }
  }, [artwork, title])

	return (
		<div className="text-xs">
      {insufficientData || loading ? (
        <p>
          Loading...
        </p>
      ) : (
        <p>
          <button className="font-bold" onClick={() => playing ? pause() : play()}>{playing ? `Pause` : `Play`}</button>
          {` `}
          <button onClick={() => skip(false)}>(–15)</button>
          {` `}
          <button onClick={() => skip(true)}>(+15)</button>
        </p>

      )}
      <input
        className="appearance-none w-full bg-transparent py-3 border-b-2 border-black rounded-none"
        value={currentTime}
        max={totalTime}
        min={0}
        onChange={seek}
        ref={seekRange}
        step="any"
        type="range"
      />
      <audio ref={audioElement} preload="metadata">
        <source src={src} type={type} />
      </audio>
      <p>{formattedTime} / {formattedTimeRemaining}</p>
		</div>
	);
};

export default Player
export { Player }