import React, { ReactElement, useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import useEventListener from "../hooks/useEventListener"

const query = graphql`
  query TweetQuery {
    allTwitterSearchTweetsHowLongGone {
      edges {
        node {
          id
          entities {
            urls {
            display_url
            expanded_url
            indices
            url
            }
            media {
              display_url
              media_url_https
              media_url
              indices
              id_str
              id
              expanded_url
              type
              url
            }
          }
          full_text
          created_at
        }
      }
    }
  }
`

const Ticker = ({ gradientColor, gradientWidth = 100, speed = 40 }: { gradientColor: string, gradientWidth?: number, speed?: number }): ReactElement | null => {
  const tweetQuery = useStaticQuery<GatsbyTypes.TweetQueryQuery>(query),
    tweets: GatsbyTypes.TweetQueryQuery["allTwitterSearchTweetsHowLongGone"]["edges"][0][] = [{
      node: {
        created_at: `${new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)}`,
        id: ``,
        entities: {
          media: [],
          urls: [],
        },
        full_text: `New episodes every Monday, Wednesday, and Friday`,
      }
    }]

  if(tweetQuery?.allTwitterSearchTweetsHowLongGone?.edges) tweets.push(...tweetQuery.allTwitterSearchTweetsHowLongGone.edges)

  const tweetsHTML = tweets.filter(edge => edge && edge.node)
    .sort((a, b) => new Date(b.node.created_at ?? `0`).getTime() - new Date(a.node.created_at ?? `0`).getTime())
    .map(edge => {
    const urls = edge?.node?.entities?.urls,
      media = edge?.node?.entities?.media,
      anchorClasses = `no-underline font-bold hover:text-secondary focus-visible:text-secondary`,
      imageClasses = `inline-block w-auto h-5`
    let tweetHtml = edge?.node?.full_text

    if(!tweetHtml) return null
    
    // Replace @username with anchor tags linking to Twitter profiles
    tweetHtml = tweetHtml?.replace(/@([a-zA-Z0-9_]{4,15})/g, `<a class="${anchorClasses}" href="https://twitter.com/$1" target="_blank" rel="noopener">$&</a>`)
    
    // Replace twitter links with anchors
    if(urls?.length) {
      tweetHtml = urls.reduce((accumulator, current) => {
        if(current?.url && current?.display_url) return accumulator.replace(current.url, `<a class="${anchorClasses}" href="${current.expanded_url}" target="_blank" rel="noopener">${current.display_url}</a>`)
        return accumulator
      }, tweetHtml)
    }
    
    // Replace twitter media with images
    if(media?.length) {
      tweetHtml = media.reduce((accumulator, current) => {
        if(current?.url && current?.expanded_url && current?.media_url_https) return accumulator.replace(current.url, `<a class="${anchorClasses}" href="${current.expanded_url}" target="_blank" rel="noopener"><img class="${imageClasses}" src="${current.media_url_https}" /></a>`)
        return accumulator
      }, tweetHtml)
    }
    
    return tweetHtml
  })

  const marqueeRef = useRef<HTMLDivElement>(null),
    containerRef = useRef<HTMLDivElement>(null),
    [mounted, setMounted] = useState(false),
    [duration, setDuration] = useState(0)

  const calculateWidth = () => {
    // Find width of container and width of marquee
    if (marqueeRef.current && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width,
        marqueeWidth = marqueeRef.current.getBoundingClientRect().width

      if (marqueeWidth < containerWidth) {
        setDuration(containerWidth / speed)
      } else {
        setDuration(marqueeWidth / speed)
      }
    }
  };

  useEffect(() => {
    setMounted(true)
  }, [])
  useEffect(calculateWidth)
  useEventListener(`resize`, calculateWidth)

  return (
    <>
      <style>{`
        .ticker::before,
        .ticker::after {
          width: ${gradientWidth ?? 0}px;
        }

        .ticker::before {
          background: linear-gradient(to right, ${gradientColor}, ${gradientColor}00);
        }

        .ticker::after {
          background: linear-gradient(to right, ${gradientColor}00, ${gradientColor});
        }
      `}</style>
      <div ref={containerRef} className={`ticker w-full overflow-x-hidden flex items-center justify-center text-center text-2xl text-white ${mounted ? `` : `opacity-0`} transition-opacity duration-100`}>
        <div ref={marqueeRef} className="flex w-max" style={{ animation: `scroll ${duration}s linear 0s infinite` }}>
          {tweetsHTML.map((html, i) => html && <div key={i} className="w-max whitespace-nowrap mr-10" dangerouslySetInnerHTML={{ __html: html }} />)}
        </div>
        <div className="flex w-max" style={{ animation: `scroll ${duration}s linear 0s infinite` }}>
          {tweetsHTML.map((html, i) => html && <div key={i} className="w-max whitespace-nowrap mr-10" dangerouslySetInnerHTML={{ __html: html }} />)}
        </div>
      </div>
    </>
  )
}

export default Ticker
export { Ticker }