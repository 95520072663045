import React, { ReactElement, useEffect, useRef } from "react"
import useEventListener from "../hooks/useEventListener"
import { debounce } from "throttle-debounce"

const generateNoise = (context: CanvasRenderingContext2D, width: number, height: number) => {
  const imageData = context.createImageData(width, height),
    buffer32 = new Uint32Array(imageData.data.buffer),
    len = buffer32.length

  for(let i = 0; i < len; i++) {
    if (Math.random() < 0.5) {
      buffer32[i] = 0xff000000
    }
  }

  return imageData
}

const Noise = (): ReactElement | null => {
  const canvas = useRef<HTMLCanvasElement>(null),
    renderNoise = () => {
      if(typeof document === `undefined`) return
      if(!canvas.current) return
      
      const context = canvas.current.getContext(`2d`)
      if(!context) return

      canvas.current.width = document.documentElement.clientWidth
      canvas.current.height = document.documentElement.clientHeight

      const noise = generateNoise(context, document.documentElement.clientWidth, document.documentElement.clientHeight)
      
      context.putImageData(noise, 0, 0)
    }

  useEffect(renderNoise, [])
  useEventListener(`resize`, debounce(500, renderNoise))

  return (
    <canvas ref={canvas} className="pointer-events-none fixed w-screen h-screen inset-0 z-50 opacity-2" />
  )
}

export default Noise
export { Noise }