import React, { HTMLProps, ReactElement, useEffect, useRef, useState } from "react"
import { GatsbyLinkProps } from "gatsby"
import useEventListener from "../hooks/useEventListener"
import { debounce } from "throttle-debounce"

const Sticker = (props: (HTMLProps<HTMLAnchorElement> | GatsbyLinkProps<any>) & { offset?: number | number[] }): ReactElement | null => {
  const { className, offset, style, ...rest } = props,
    [mounted, setMounted] = useState(false),
    invert = useRef<boolean>(),
    [left, setLeft] = useState<number>(),
    [top, setTop] = useState<number>(),
    [rotation, setRotation] = useState<number>(),
    [colorClasses, setColorClasses] = useState<string>(),
    computedStyle = Object.assign({}, style, {
      left: `${left}px`,
      opacity: left ? undefined : `0`,
      top: `${top}px`,
      transform: `translate(-50%, -50%) rotate(${rotation}deg)`
    }),
    previousClientWidth = useRef<number>(),
    calculatePosition = (dimension?: `x` | `y`) => {
      if(dimension === `x`) return Math.round(Math.random() * document.documentElement.clientWidth)
      if(dimension === `y`) return Math.round(Math.random() * document.documentElement.clientHeight)
      return [Math.round(Math.random() * document.documentElement.clientWidth), Math.round(Math.random() * document.documentElement.clientHeight)]
    },
    placeSticker = (invert: boolean) => {
      if(typeof document === `undefined`) return

      // Don’t re-render if client width hasn’t changed (e.g., resize is a result of mobile scroll)
      const clientWidth = document.documentElement.clientWidth,
        clientHeight = document.documentElement.clientHeight

      if(clientWidth === previousClientWidth.current) return
      previousClientWidth.current = clientWidth

      const offX = typeof offset === `number`
          ? offset
          : Array.isArray(offset)
            ? offset[0]
            : undefined,
        offY = typeof offset === `number`
        ? offset
        : Array.isArray(offset)
          ? offset[1]
          : undefined

      let [newLeft, newTop] = calculatePosition() as number[]

      if(offX) {
        while(newLeft < offX || newLeft > clientWidth - offX) {
          newLeft = calculatePosition(`x`) as number
        }
      }

      if(offY) {
        while(newTop < offY || newTop > clientHeight - offY) {
          newTop = calculatePosition(`y`) as number
        }
      }

      setLeft(newLeft)
      setTop(newTop)
      setRotation((Math.random() - 0.5) * 30)
      setColorClasses(invert
        ? `bg-white hover:bg-primary focus-visible:bg-primary text-primary hover:text-white focus-visible:text-white border-primary hover:border-white focus-visible:border-white`
        : `bg-primary hover:bg-white focus-visible:bg-white text-white hover:text-primary focus-visible:text-primary border-white hover:border-primary focus-visible:border-primary`
      )
    }

  useEffect(() => {
    invert.current = Math.random() > 0.5
    placeSticker(invert.current)
  }, [])

  useEffect(() => {
    setMounted(true)
  }, [])
  useEventListener(`resize`, debounce(500, () => placeSticker(invert.current ?? false)))

  return (
    <div className={`${mounted ? `` : `opacity-0`} transition-opacity duration-100`}>
      <a className={className ? className : `fixed z-20 px-2 py-2.5 border-2 font-windsor uppercase no-underline rounded-100 ${colorClasses} transition-colors duration-100`} style={computedStyle} {...rest} />
    </div>
  )
}

export default Sticker
export { Sticker }