import { useState } from "react"
import { windowIfAvailable } from "../utilities"
import { useEventListener, EventHandler, EventListenerOptions } from "./useEventListener"


interface EventConditionProps<T extends Event> {
  event: string,
  handler: EventHandler<T>,
  element?: Document | Window | HTMLElement,
  options?: EventListenerOptions,
  initialValue?: boolean,  
}

const useEventCondition = <T extends Event>({
  event,
  handler,
  element = windowIfAvailable(),
  options,
  initialValue = false,
}: EventConditionProps<T>): boolean => {
  const [value, setValue] = useState(initialValue)

  // Wrap user-provided event handler with a function that stores a boolean of the result
  const wrapper = (e: T) => {
    const eventHandlerValue = Boolean(handler(e))
    if(eventHandlerValue !== value) setValue(eventHandlerValue)
  }

  useEventListener(event, wrapper, element, options)

  return value
}

const useEventConditions = <T extends Event>(eventConditionArray: EventConditionProps<T>[]): boolean => {
  const [value, setValue] = useState(false)

  eventConditionArray.forEach(({
    event,
    handler,
    element = windowIfAvailable(),
    options,
  }) => {
    // Wrap user-provided event handler with a function that stores a boolean of the result
    const wrapper = (e: T) => {
      const eventHandlerValue = Boolean(handler(e))
      if(eventHandlerValue !== value) setValue(eventHandlerValue)
    }
  
    useEventListener(event, wrapper, element, options)
  })

  return value
}

export default useEventCondition
export { useEventCondition, useEventConditions }